/*
*
*
* create by wuwc 2020-10-20
*
*
* */
import Vue from 'vue';
import VueRouter from 'vue-router'

Vue.use(VueRouter);
const routes = [
    {
        path: '/:id?/:flag?',
        name: 'codeHtmlIndex',
        component: () => import('../views/html/index')
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
});

export default router;
